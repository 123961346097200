import React from "react";

const Map = () => {
  return (
    <>
      <div className="google-map">
        <div className="embed-responsive embed-responsive-21by9">
          <iframe
            className="embed-responsive-item"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5677.172423444767!2d-63.58051835051541!3d44.646373499111284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b5a2232d2047a23%3A0xf07ce7a2ef99c31e!2sDowntown%20Halifax%2C%20Halifax%2C%20NS!5e0!3m2!1sen!2sca!4v1631138192046!5m2!1sen!2sca"
          ></iframe>
        </div>
      </div>
      {/* End google-map */}
    </>
  );
};

export default Map;
