import React from "react";
import Skills from "../skills/Skills";
import Fade from "react-reveal/Fade";

const resumeContent = [
  {
    jobPosition: `Lead Product Manager`,
    jobType: `Toronto`,
    jobDuration: `May 2022 - Aug 2022`,
    timeDuraton: `Proptech`,
    compnayName: "Bidmii",
    jobDescription: `Bidmii is a marketplace that brings together a fragmented industry for 
    homeowners and contractors where homeowners can easily hire reliable and trusted contractors 
    to complete their projects; and contractors can easily connect with qualified homeowners 
    allowing them to focus on getting the job done. As the company’s first and only product manager, 
    I introduced agile methodologies to the small team operating for over 6000 users. Bidmii Pro, 
    through iterative deployment, was released as the company’s first initiative in a monetization 
    strategy that introduced $60,000 in ARR. I also led the strategy and execution on a national 
    expansion through roadmapping that increased our total homeowner and contractor user base by 37%. `,
  },
  {
    jobPosition: `Product Manager`,
    jobType: `Toronto`,
    jobDuration: `Feb 2021 - Dec 2021`,
    timeDuraton: `SaaS`,
    compnayName: "Afino",
    jobDescription: `Afino is a pre-seed SaaS startup aimed at building stronger 
    distributed teams by driving more meaningful connections. Being such an early 
    startup, I helped the company establish its north star metric to clearly define 
    how the company of the success will be measured. I also helped launch the core 
    features for their main product including the Afino profile. `,
  },
  {
    jobPosition: `Product Manager`,
    jobType: `Toronto`,
    jobDuration: `May 2021 - Aug 2021`,
    timeDuraton: `Fintech`,
    compnayName: "SA Capital",
    jobDescription: `SA Capital is a private trading and investing group that 
    provides a community in which customers can learn and educate themselves 
    from live discussion, trade ideas, educational content, and other resources. 
    For SA Capital, I helped the group launch 5 products: Investing, Forex, Crypto, 
    Equities, Platinum packages. Each product entailed defining a use case along with 
    user segments and aligning the group’s core values with each product. With the launch, 
    I worked closely with the CMO on the go-to-market strategy. This involved looking into 
    customer acquisition channels along with customer messaging and insights to determine 
    how to best position each product.`,
  },
      {
    jobPosition: `Product Manager`,
    jobType: `Halifax`,
    jobDuration: `May 2021 - July 2021`,
    timeDuraton: `Consumer Electronics`,
    compnayName: "Apple",
    jobDescription: `AS a side project, I simulated the role of a product manager at Apple and created a value-add 
    on feature to Apple’s existing clocks app. The purpose of this project was to identify a 
    problem with its customer segmentations and personas that each would have their pain points. 
    Then a few solutions were identified to address those pain points and supplemented with user 
    stories. Once the solution was selected, wireframes were created and a brief go-to-market and 
    launch strategy were planned. The problem I identified was to address the $411B US loss in 
    productivity from failing to wake up in the morning. `,
  },
  {
    jobPosition: `Product Manager`,
    jobType: `Toronto`,
    jobDuration: `June 2020 - August 2021`,
    timeDuraton: `Telecommunications`,
    compnayName: "Bell",
    jobDescription: ` Bell is Canada's largest telecommunications company, providing mobile
    phone, TV, high speed and wireless Internet, and residential home phone services. During 
    my time at Bell I managed the Bell Wi-Fi App along with Bell’s email product, Webmail. 
    For the Bell Wi-Fi App, I launched app releases that included 3-4 features per release 
    for 315K MAU. For Bell Webmail, I implemented a migration to a new UI and back-end for 
      300K MAU effectively increasing mobile usage by 20% and reducing call rates by 50%.`,
  },
  {
    jobPosition: `Strategy Consultant`,
    jobType: `Toronto`,
    jobDuration: `April 2018 - April 2020`,
    timeDuraton: `Consulting`,
    compnayName: "QUBE",
    jobDescription: `Queen’s University Business and Engineering (QUBE) is a student-run 
    multi-operational corporation whose consulting division is primarily focused on technology 
    and operations services, while still allowing for management and pure strategy projects to 
    be undertaken. For a high-tech mid-market transportation player, our team acquired approval 
    from the president of a region for the implementation of our client’s multi-billion-dollar 
    transportation system. For an AI/ML startup, we provided our client with quantitative & qualitative 
    data to pitch to VC firms and investors. `,
  },

];

const educatonContent = [
  {
    passingYear: "2017-2022",
    degreeTitle: "Bachelor of Applied Science and Engineering",
    instituteName: "Queen's University",
  },
  {
    passingYear: "2021",
    degreeTitle: "Product Manager Certificate",
    instituteName: "Product School",
  },
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <Fade bottom key={i}>
                <div className="resume-row">
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div className="rb-left">
                        <h6>{val.jobPosition}</h6>
                        <label>{val.jobType}</label>
                        <p>{val.jobDuration}</p>
                        <div className="rb-time">{val.timeDuraton}</div>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-9">
                      <div className="rb-right">
                        <h6>{val.compnayName}</h6>
                        <p>{val.jobDescription}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Education & Skills</h3>{" "}
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 m-15px-tb">
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <Fade bottom>
                      <span>{val.passingYear}</span>
                      <h6>{val.degreeTitle} </h6>
                      <p>{val.instituteName}</p>{" "}
                    </Fade>
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div className="col-lg-7 ml-auto m-15px-tb">
              <Fade>
                <Skills />{" "}
              </Fade>
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
