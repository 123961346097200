import React from "react";
import Fade from "react-reveal/Fade";

const ServiceContent = [
  {
    icon: "icon-mobile",
    title: "Product Vision and Stratgey",
    descriptions: `Working with a pre-seed startup and an established telecom company, 
    I understand what a product’s vision should be to deliver the best user experience
    regardless of the size of user base.`,
  },
  {
    icon: "icon-desktop",
    title: "Opportunity Hypotheses",
    descriptions: `Through product pitches, I’ve created and validated opportunity 
    hypotheses for products and features.`,
  },
  {
    icon: "icon-target",
    title: "Consulting",
    descriptions: `I love talking with founders about their ventures and how they can 
    accomplish product-led growth for their companies.`,
  },
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01">
              <Fade bottom>
                <div className="icon">
                  <i className={`icon ${val.icon}`}></i>
                </div>
                <div className="feature-content">
                  <h5>{val.title}</h5>
                  <p>{val.descriptions}</p>
                </div>
              </Fade>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
