import React from "react";

const skillContent = [
  {
    name: "UI/UX",
    percent: "85",
    numberPercent: "85",
  },
  {
    name: "Data Analytics",
    percent: "80",
    numberPercent: "80",
  },
  {
    name: "Napping",
    percent: "100",
    numberPercent: "100",
  },
    {
    name: "Digital Marketing",
    percent: "88",
    numberPercent: "88",
  },
];

const Skills = () => {
  return (
    <>
      <div className="skill-wrapper">
        {skillContent.map((skill, i) => (
          <div className="skill-lt" key={i}>
            <h6>{skill.name}</h6>
            <span>{skill.percent}%</span>
            <div className="skill-bar">
              <div
                className="skill-bar-in"
                style={{ width: skill.numberPercent + "%" }}
              ></div>
            </div>
          </div>
          // End skill-lt
        ))}
      </div>
    </>
  );
};

export default Skills;
