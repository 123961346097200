import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
        {
      imageName: "team-4",
      desc: `Jaechan is an outstanding product manager. He has a keen 
      eye for detail and understands how to translate customer feedback 
      into actionable product improvements. Jaechan is also a great communicator, 
      which enables him to effectively collaborate with cross-functional teams. 
      I always enjoyed working with Jaechan and would recommend him without hesitation.`,
      reviewerName: "Jon Christensen",
      designation: "CEO at Bidmii",
    },
    {
      imageName: "team-1",
      desc: `Jae worked as a Product Manager on my team in a co-op 
      position for over a year. With Jae’s technical background, keen 
      sense for business, and can-do attitude he began contributing 
      to the team immediately. He took full advantage of the opportunity 
      to learn from those around him and took it upon himself to seek 
      out external resources such as the Product School certificate, 
      quickly evolving from “Co-op/Intern” to "Product Manager.” Jae 
      became an integral and reliable member of the team responsible 
      for driving new feature releases for Bell’s Wi-Fi mobile app 
        and browser-based Email application. `,
      reviewerName: "David Johnston",
      designation: "Senior/Group Product Manager at Bell",
    },
    {
      imageName: "team-2",
      desc: `Jae came to our team with the insights we never knew we eneded. 
      His understanding of a product's development cycle helped us launch our
       first 5 products for our group. Thanks to him, getting our product out 
       the door was smoother than we thought and our customers appreciate the 
       value they get from the products.`,
      reviewerName: "Saim Abbasi",
      designation: "CEO and Founder at SA Capital",
    },
    {
      imageName: "team-3",
      desc: `Jae has been an integral part of our team since he joined back in 
      February. As a pre-seed company, having an innovative thinker like Jae on 
      the product team has made an immense difference in driving growth. His strong 
      analytical mindset was an asset when evaluating usage patterns and finding 
      potential growth opportunities for Afino. Given his continuous curiosity 
      and passion to uncover real customer problems, I’m looking forward to seeing 
      where his bright future in the product world takes him.`,
      reviewerName: "Javier Sanchez Mejorda",
      designation: "CPO at Afino",
    },
  ];

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div key={i}>
            <div className="testimonial-01 media">
              <Fade bottom>
                <div className="avatar">
                  <img
                    src={`img/testimonial/${val.imageName}.png`}
                    alt="review comments"
                  ></img>
                </div>
                <div className="media-body">
                  <p>{val.desc}</p>
                  <h6>{val.reviewerName}</h6>
                  <span>{val.designation}</span>
                </div>
              </Fade>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
