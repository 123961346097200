import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>Coffee? </h4><h4>I'll get the donuts</h4>
        <p>
          Always available for a good ol' chat. 
            Feel free to contact me!
        </p>

        <ul>


          <li className="media">
            <i className="icon icon-envelope"></i>
            <span className="media-body">jaechanyoo@gmail.com</span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body">+1 902-989-0496</span>
          </li>
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
