import React from "react";
import TextLoop from "react-text-loop";
import Fade from "react-reveal/Fade";

const conctInfo = {
  phone: "+1 902-989-0496",
  email: "jaechanyoo@gmail.com",
};

const sliderContent = {
  name: "Jaechan Yoo",
  designation: "Product Manager",
  description: `I went into engineering because I wanted to be Iron Man.
  Unfortunately that didn't pan out.`,
  btnText: " Donwload CV",
};

const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href="tel:+1 902-989-0496">{conctInfo.phone}</a>
            <a href="mailto:mail%20to:jaechanyoo@gmail.com,com">
              {conctInfo.email}
            </a>
          </div>
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <Fade bottom>
                  <h6>Hello! My name is</h6>
                  <h1 className="font-alt">{sliderContent.name}</h1>
                  <TextLoop>
                    <p className="loop-text lead">Product Manager</p>
                    <p className="loop-text lead">Chef</p>
                    <p className="loop-text lead">Playlist Curator</p>
                  </TextLoop>{" "}
                  <p className="desc">{sliderContent.description}</p>
                  <div className="mt-4">
                    <a
                      className="px-btn px-btn-white"
                      href="img/Jaechan Yoo Resume.pdf"
                      download
                    >
                      {sliderContent.btnText}
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/slider/home-banner.png"
            })`,
          }}
        ></div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
